mat-select {
	--mat-select-disabled-trigger-text-color: var(--text-primary-color);
	width: 100%;
	box-sizing: border-box;
	color: inherit;
	border: 1px solid var(--primary-border-color);
	background-color: var(--primary-contrast-color);
	border-radius: 4px;
	padding-block: var(--space-xs);
	min-height: 40px;
}

mat-select.mat-mdc-select {
	align-items: center;
	display: inline-flex;
}

.mat-mdc-select-disabled {
	background-color: var(--background-dark-color);
	color: var(--text-primary-color);
}

.mat-mdc-select-trigger {
	padding-inline: var(--space-xs);
}

.mat-mdc-optgroup-label {
	font-weight: 500;
}
