:root {
	--space-m: 16px;
	--space-xs: calc(var(--space-m) * 0.5);
	--space-s: calc(var(--space-m) * 0.75);
	--space-l: calc(var(--space-m) * 1.5);
	--space-xl: calc(var(--space-m) * 2);
	--space-xxl: calc(var(--space-m) * 2.5);
}

@media screen and (max-width: 480px) {
	:root {
		--space-m: 8px;
	}
}

.padding-m {
	padding: var(--space-m);
}

.padding-xs {
	padding: var(--space-xs);
}

.padding-s {
	padding: var(--space-s);
}

.padding-l {
	padding: var(--space-l);
}

.padding-xl {
	padding: var(--space-xl);
}

.padding-xxl {
	padding: var(--space-xxl);
}
