/** Custom typography style. */
.typo-display {
	font-size: 2.5rem;
}

.typo-headline {
	font-size: 1.75rem;
}

.typo-header {
	font-size: 1.5rem;
}

.typo-subheader {
	font-size: 1.25rem;
}

.typo-body {
	font-size: 1rem;
}

.typo-body1 {
	font-size: 0.75rem;
}
