mat-expansion-panel {
	/* Need `important` here to override the material styles. */
	/* stylelint-disable-next-line declaration-no-important */
	box-shadow: none !important;
}

/** Component specific styles. */
.expansion-container.no-spacing .mat-expansion-panel-header,
.expansion-container.no-spacing .mat-expansion-panel-content .mat-expansion-panel-body {
	padding: 0;
}

.expansion-container mat-expansion-panel-header.expanded .mat-expansion-indicator {
	padding-left: 2px;
}

.expansion-container mat-expansion-panel-header.collapsed .mat-expansion-indicator {
	padding-right: 2px;
}
