h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

.page-title {
	width: 100%;
	text-align: center;
	margin-bottom: var(--space-xxl);
}
