.mat-datepicker-toggle {
	position: absolute;
	right: 0;
	top: 0;
}

.mat-mdc-icon-button .mat-mdc-button-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
	display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base {
	padding: 0;
}
