button.button,
a.button {
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 8px;
	text-decoration: none;
	padding: 8px 12px;
	background-color: var(--primary-color);
	border: none;
	font-weight: 500;
	color: var(--primary-contrast-color);
	transition:
		background-color,
		opacity 300ms ease-in-out;
	cursor: pointer;
	justify-content: center;
}

button.button:not(:disabled):hover,
a.button:not(:disabled):hover {
	background-color: var(--primary-light-color);
}

button.button:not(:disabled):active,
a.button:not(:disabled):active {
	background-color: var(--primary-dark-color);
}

button.button:disabled,
a.button:disabled {
	cursor: not-allowed;
	opacity: 0.6;
}

/* Styles for outlined button. */
button.button_outlined,
a.button_outlined {
	background-color: var(--primary-contrast-color);
	border: 1px solid var(--primary-border-color);
	color: var(--text-primary-color);
}

button.button_outlined:not(:disabled):hover,
a.button_outlined:not(:disabled):hover {
	background-color: var(--primary-dark-contrast-color);
}

/* Styles for icon button. */
button.icon-button,
a.icon-button {
	display: flex;
	align-items: center;
	padding: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	color: var(--primary-color);
}

button.icon-button:not(:disabled):hover,
a.icon-button:not(:disabled):hover {
	background-color: transparent;
}

button.icon-button:not(:disabled):active,
a.icon-button:not(:disabled):active {
	background-color: transparent;
}
