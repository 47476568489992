:root {
	/*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */
}
