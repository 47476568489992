.mdc-checkbox__ripple {
	/* Disable ripple effect for shipment driver checkin. */
	/* stylelint-disable-next-line declaration-no-important */
	opacity: 0 !important;
}

.mat-mdc-checkbox-ripple {
	/* Disable ripple effect for shipment driver checkin. */
	display: none;
}
