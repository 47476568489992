:root {
	/*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */

	/** Prevalent color in the application */
	--primary-color: #82150f;
	--primary-contrast-color: #ffffff;
	--primary-dark-color: #7c2228;
	--primary-dark-contrast-color: #ffffff;
	--primary-light-color: #95383e;
	--primary-light-contrast-color: #000000;
	--secondary-color: #55574e;
	--secondary-color-light: #cccccc;

	/** Background color. */
	--background-color: #ffffff;
	--background-dark-color: #ededed;
	--background-contrast-color: #000000;

	/** Text color. */
	--text-primary-color: #000000;
	--text-primary-contrast-color: #ffffff;
	--text-secondary-color: #7a7a7a;

	/** Error color. */
	--error-color: red;

	/** Border styles. */
	--border-radius: 8px;
	--border-radius-lg: 16px;
	--primary-border-color: #cfcfcf;

	/** Color for box shadow. */
	--box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
	--box-shadow-lg: 0px 16px 48px 0px rgba(0, 0, 0, 0.18);

	/** Size variables. */
	--header-height: 100px;

	/** Color for divider. */
	--divider-color: #dddddd;
	--divider-color-light: #f4f4f4;
}
